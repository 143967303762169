import React, { Component } from 'react'
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react'
import styles from './product.module.css'
import { Parallax } from 'react-scroll-parallax'

class Product extends Component {
  static contextTypes = {
    parallaxController: PropTypes.object.isRequired,
  }

  handleLoad = () => {
    this.context.parallaxController.update();
  }

  render() {
    const { props, handleLoad } = this

    return (
      <SbEditable content={props.blok}>
        <div className={styles.productWrapper}>
          <article className={styles.product}>
            <img
              className={styles.coverimage}
              src={props.blok.coverimage}
              alt={props.blok.headline}
              onLoad={handleLoad}
            />
            <div className={styles.content}>
              <p className={styles.type}>{props.blok.type}</p>
              <h1 className={styles.headline}>{props.blok.headline}</h1>
              <p className={styles.author}>By {props.blok.author}</p>
              <p className={styles.description}>
                {props.blok.description.split('\n').map((line, i) => {
                  return <span key={i}>{line}</span>
                })}
              </p>
              { props.blok.printlink.url &&
                <a
                  className={styles.printlink}
                  href={props.blok.printlink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.blok.printlinkcopy}
                </a>
              }
              { props.blok.pdflink.url &&
                <a
                  className={styles.pdflink}
                  href={props.blok.pdflink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.blok.pdflinkcopy}
                </a>
              }
            </div>
          </article>
          <Parallax
            className={styles.parallax}
            offsetYMax={20}
            offsetYMin={-20}
            slowerScrollRate
            tag="figure"
          >
              <div className={styles.backgroundimage}>
                <img
                  src={props.blok.backgroundimage}
                  alt={props.blok.headline}
                  onLoad={this.handleLoad}
                />
              </div>
          </Parallax>
        </div>
      </SbEditable>
    )
  }
}

export default Product