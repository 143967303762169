import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';
import Header from './header/header'
import Components from './components.js'

const Page = (props) => {
  return (
    <ParallaxProvider>
      <Header />
      {props.blok.body && props.blok.body.map((blok) => {
        return React.createElement(Components[blok.component], {key: blok._uid, blok: blok})
      })}
    </ParallaxProvider>
  )
}

export default Page