import React from 'react'
import SbEditable from 'storyblok-react'
import styles from './blurb.module.css'

const Blurb = (props) => {
  return (
    <SbEditable content={props.blok}>
      <article className={styles.blurb}>
        <img className={styles.image} src={props.blok.image} alt={props.blok.headline} />
        <div className={styles.content}>
          <h1 className={styles.headline}>{props.blok.headline}</h1>
          <p className={styles.paragraph}>{props.blok.paragraph}</p>
          <p className={styles.bodycopy}>{props.blok.bodycopy}</p>
          <a
            className={styles.link}
            href={props.blok.link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
              {props.blok.buttoncopy}
            </a>
        </div>
      </article>
    </SbEditable>
  )
}

export default Blurb