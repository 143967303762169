import React from 'react'
// import Skull from '../gfx/skull'
import Logo from '../gfx/logo'
import styles from './header.module.css'

const Header = () => (
  <header className={styles.header}>
    <div className={styles.slogan}>
      <span>spel</span>
      <span>doom</span>
      <span>hat</span>
    </div>
    <Logo />
    <div className={styles.more}>More</div>
  </header>
)

export default Header
